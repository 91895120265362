import { Component, OnInit } from '@angular/core';
import { DisplayApiService } from 'src/app/api/services/display-api.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { ScreenSharingService } from '../../services/screen-sharing.service';
import { RvshareAppDetectionService } from '../../services/rvshare-app-detection.service';

@Component({
  selector: 'moderator-join',
  templateUrl: './moderator-join.component.html',
  styleUrl: './moderator-join.component.scss'
})
export class ModeratorJoinComponent implements OnInit {

  availableDisplays: any[];
  tableColumns = ['name', 'status'];
  savingFavorite: string;

  search: any = {
    filter: 'licensedToScreenShare:=1&&screenSharingMode:=moderated',
    includeSubcompanies: false
  };

  constructor(
    private displayApiService: DisplayApiService,
    protected userStateService: UserStateService,
    private rvshareAppDetectionService: RvshareAppDetectionService,
    protected screenSharingService: ScreenSharingService
  ) {

  }

  ngOnInit(): void {
    this.displayApiService.list(this.search).then((result) => {
      if (result.items && result.items.length > 0) {
        this.availableDisplays = this.sortDisplays(result.items);
      } else {
        this.availableDisplays = [];
      }
    });
  }

  getDisplay(displayId: string) {
    return this.availableDisplays.find((display) => display.id === displayId);
  }

  isScreenShareApp() {
    return this.rvshareAppDetectionService.isScreenShareApp();
  }

  isFavored(display: any): boolean {
    const userEmail = this.userStateService.getUserEmail();
    return !!(display?.favoredBy?.find((by) => by === userEmail));
  }

  private sortDisplays(displayList: any[]): any[] {
    return displayList.filter((item) => this.isFavored(item))
      .concat(
        displayList.filter((item) => !this.isFavored(item)
      )
    );
  }

  private updateFavorite(display: any, email: string): void {
    if (this.isFavored(display)) {
      let index = display.favoredBy.indexOf(email);
      if (index >= 0) {
        display.favoredBy.splice(index, 1);
      }
    } else {
      display.favoredBy = display.favoredBy ?? [];
      display.favoredBy.push(email);
    }
  }

  toggleFavorite(displayId: string) {
    const display = this.getDisplay(displayId);
    const email = this.userStateService.getUserEmail();
    this.updateFavorite(display, email);

    this.savingFavorite = displayId;
    this.displayApiService.update(displayId, display)
    .catch((error) => {
      console.error(error);
      this.updateFavorite(display, email);
    }).finally(() => {
      this.savingFavorite = '';
      this.availableDisplays = this.sortDisplays(this.availableDisplays);
    });
  }

}
