<div class="container px-4" rvSpinner="screen-share-loader" [rvShowSpinner]="!availableDisplays">
  <div class="app-header u_margin-md-top mb-5">
    <h1 class="app-header-title" id="title">Screen Share</h1>
  </div>
  <div class="moderator-join-layout">
    <div>
      @if (screenSharingService.channelError) {
        <div class="madero-style alert alert-danger mb-3">
          <div class="flex flex-row align-start">
            <mat-icon fontIcon="fa-times-circle" class="icon-left"></mat-icon>
            <p class="mb-0">
              {{ screenSharingService.channelError }}
            </p>
          </div>
        </div>
      }
      <p class="py-4">Choose a display to start a moderated session and invite participants to share their screen.</p>
    </div>
    <div>
      @if (!userStateService.isModerator() || (availableDisplays && availableDisplays.length < 1)) {
        <div class="p-5 align-center border-container light-border">
        <img src="/images/screen-sharing/hot-air-balloon.png" width="200" height="200" alt="Hot Air Balloon">
          @if (!userStateService.isModerator()) {
            <h5>Permission Needed</h5>
            <p>
              You don't have permission to use Screen Share Moderated Session.
              Please ask the admin to assign you the role of "Screen Share Moderator".
            </p>
            <a mat-flat-button color="primary" class="my-4" uiSref="apps.user.details">
              See My User Roles
            </a>
          }
          @else {
            <h5>Enable Screen Share License</h5>
            <p>
              To start a moderated session, enable a screen share license on a display.
              Once enabled, the display will appear on this list.
            </p>
            <a mat-flat-button color="primary" class="my-4" uiSref="apps.displays.list">
              See Displays
            </a>
          }
        </div>
      }
      @else if (availableDisplays) {
        <div class="border-container light-border">
          <table mat-table [dataSource]="availableDisplays">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>
                <div class="pl-2">Display Name</div>
              </th>
              <td mat-cell *matCellDef="let display">
                <div class="flex flex-row gap-2">
                  @if (savingFavorite === display.id) {
                    <mat-spinner diameter="15" class="ml-2" style="margin-right: 6.5px"></mat-spinner>
                  }
                  @else {
                    <button
                      mat-icon-button
                      class="small-icon-button mr-1"
                      (click)="toggleFavorite(display.id)"
                      [attr.aria-label]="isFavored(getDisplay(display.id)) ? 'Remove from Favorites' : 'Add to Favorites'"
                    >
                      @if (isFavored(getDisplay(display.id))) {
                        <mat-icon fontIcon="fa-star" class="rise-blue"></mat-icon>
                      }
                      @else {
                        <mat-icon fontIcon="fa-star-o" class="rise-gray"></mat-icon>
                      }
                    </button>
                  }
                  <div>{{ display.name }}</div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef width="180">Status</th>
              <td mat-cell *matCellDef="let display">
                <div class="flex flex-row gap-2 font-weight-bold">
                  <div class="flex-grow u_capitalize" [ngClass]="{ 'rise-green': display.onlineStatus === 'online', 'rise-gray': display.onlineStatus !== 'online' }">
                    {{ display.onlineStatus.replace('_', ' ') }}
                  </div>
                  @if (display.onlineStatus === 'online') {
                    <a mat-icon-button aria-label="Start Session" class="u_lh-1" uiSref="apps.screen-sharing.moderator-room" [uiParams]="{displayId: display.id, rvshare: isScreenShareApp()}">
                      <mat-icon svgIcon="chevron-right"></mat-icon>
                    </a>
                  }
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
          </table>
        </div>
      }
    </div>
  </div>
</div>
