<ng-template #scheduleSelectorTooltip>
  <span id="schedule-selector-tooltip">
    <div class="row">
      <div class="col-xs-12">
        <search-filter id="search-schedules" [filterConfig]="filterConfig" [search]="scheduleSelectorService.search" [doSearch]="scheduleSelectorService.unselectedSchedules?.doSearch" iconSet="madero"></search-filter>
      </div>
    </div>

    <div class="scrollable-list mt-3 mb-3"
    scrollingList (scrollEndEvent)="scheduleSelectorService.unselectedSchedules?.load()"
      rvSpinner="selected-schedules-spinner" [rvShowSpinner]="scheduleSelectorService.loadingSchedules || scheduleSelectorService.unselectedSchedules?.loadingItems">
      @for (schedule of scheduleSelectorService.selectedSchedules; track schedule) {
        @if (filterSchedule(schedule)) {
          <div class="flex-row">
            <div class="row-entry">
              <mat-checkbox (click)="scheduleSelectorService.selectItem(schedule, true)" [checked]="scheduleSelectorService.isSelected(schedule, true)"></mat-checkbox>
              <div class="mr-auto u_clickable u_text-ellipsis" (click)="scheduleSelectorService.selectItem(schedule, true)">
                {{schedule.name}}
              </div>
              <div class="u_float-left mr-3">
                <a class="madero-link" uiSref="apps.schedules.details" [uiParams]="({scheduleId: schedule.id})" (click)="closeModal()">
                  Edit
                </a>
              </div>
            </div>
          </div>
        }
      }

      @for (schedule of scheduleSelectorService.unselectedSchedules?.items.list; track schedule) {
        <div class="flex-row">
          <div class="row-entry">
            <mat-checkbox (click)="scheduleSelectorService.selectItem(schedule, false)" [checked]="scheduleSelectorService.isSelected(schedule, false)"></mat-checkbox>
            <div class="mr-auto u_clickable u_text-ellipsis" (click)="scheduleSelectorService.selectItem(schedule, false)">
              {{schedule.name}}
            </div>
            <div class="u_float-left mr-3">
              <a class="madero-link" uiSref="apps.schedules.details" [uiParams]="({scheduleId: schedule.id})" (click)="closeModal()">
                Edit
              </a>
            </div>
          </div>
        </div>
      }


      @if (!scheduleSelectorService.unselectedSchedules?.loadingItems && !scheduleSelectorService.unselectedSchedules?.search.query && ((scheduleSelectorService.selectedSchedules.length + scheduleSelectorService.unselectedSchedules?.items.list.length) === 0)) {
        <div id="noItems" class="schedule-list-empty">
          No Schedules
        </div>
      }
      @if (!scheduleSelectorService.unselectedSchedules?.loadingItems && scheduleSelectorService.unselectedSchedules?.search.query && ((scheduleSelectorService.selectedSchedules.length + scheduleSelectorService.unselectedSchedules?.items.list.length) === 0)) {
        <div id="noSearchResult" class="schedule-list-no-results">
          No Results Found
        </div>
      }
    </div>

    <div class="row">
      <div class="col-xs-6">
        <button type="button" class="btn btn-default btn-block" (click)="toggleTooltip()">Cancel</button>
      </div>
      <div class="col-xs-6">
        <button type="button" class="btn btn-primary btn-block" (click)="select()" [disabled]="scheduleSelectorService.selectedCount === 0">Select</button>
      </div>
    </div>
    <hr/>

    <div class="row" *requireRole="'!ap'">
      <div class="col-xs-12">
        <button type="button" class="btn btn-default btn-block" (click)="addSchedule()">Add Schedule</button>
      </div>
    </div>

  </span>
</ng-template>
<button tabindex="0" id="schedule-selector" type="button" class="form-control btn-select btn-block"
  #tooltipTrigger="bs-tooltip"
  triggers=""
  [tooltip]="scheduleSelectorTooltip"
  tooltipAnimation="false"
  [adaptivePosition]="false"
  placement="bottom left"
  containerClass="madero-style tooltip tooltip-schedule-selector ml-0 {{ additionalTooltipClass }}"
  container="body"
  (onShown)="showScheduleSelector()"
  (onHidden)="hideScheduleSelector()"
  (click)="toggleTooltip()"
>
  @if (scheduleSelectorService.selectedSchedules.length === 0) {
    <span class="text-muted">Select or add a new schedule</span>
  }
  @else {
    <span>{{scheduleSelectorService.selectedSchedules.length}} schedules selected</span>
  }
	<span class="pull-right text-muted"><i class="fa fa-sort"></i></span>
</button>
