<div id="branding" [shown]="brandingComponent">
  <label>Settings for all Presentations:</label>

  <div class="attribute-row attribute-row-hover">
    <div class="col-xs-12 pl-0 attribute-desc">
      <streamline-icon aria-hidden="true" class="streamline-component-icon" name="{{ componentsService.getComponentIcon(brandingComponent) }}" width="24" height="18"></streamline-icon>
      <a tabindex="0" id="branding-edit" class="madero-link" (click)="componentsService.editComponent(brandingComponent);" (keyup.enter)="componentsService.editComponent(brandingComponent)">Brand Settings</a>
    </div>
  </div>

  <label class="u_margin-md-top">Settings for this Presentation:</label>
</div>

<div class="attribute-row attribute-row-hover schedules-attribute-row mt-2" *requireRole="'cp ap'">
  <div class="col-xs-12 pl-0 attribute-desc" [ngClass]="{'danger' : schedulesComponent && schedulesComponent.showNoSchedulesError && !schedulesComponent.service.hasSelectedSchedules }">
    <streamline-icon aria-hidden="true" class="streamline-component-icon" name="schedule" width="24" height="18"></streamline-icon>
    <a tabindex="0" class="madero-link" (click)="componentsService.editComponent(schedulesComponent);" (keyup.enter)="componentsService.editComponent(schedulesComponent)">Schedules</a>
    <div class="error-message mb-1" [shown]="schedulesComponent && schedulesComponent.showNoSchedulesError && !schedulesComponent.service.hasSelectedSchedules">
      No schedule selected.
    </div>
  </div>
</div>

<div id="branding-colors-override" [shown]="colorsComponent">
  <div class="attribute-row attribute-row-hover">
    <div class="col-xs-12 pl-0 attribute-desc">
      <streamline-icon aria-hidden="true" class="streamline-component-icon" name="{{ componentsService.getComponentIcon(colorsComponent) }}" width="24" height="18"></streamline-icon>
      <a tabindex="0" id="branding-colors-override-edit" class="madero-link" (click)="componentsService.editComponent(colorsComponent);" (keyup.enter)="componentsService.editComponent(colorsComponent)">Colors</a>
    </div>
  </div>
</div>

<div class="attribute-row u_no-border mt-2">
  <div class="attribute-desc flex-grow">
    <label class="mt-2">Components</label>
  </div>
  <button tabindex="0" aria-label="Add component" class="btn btn-primary btn-block btn-add-component" (click)="showUserComponentSelector()">
    +
  </button>
</div>

<template-attribute-components isUserComponents="true"></template-attribute-components>

<div class="mt-4">
  <div class="flex-row">
    <label class="mt-1">Base Components</label>
    <streamline-icon matTooltip="Base components are part of the template and can not be moved or deleted, only hidden."
                     matTooltipPosition="right"
                     matTooltipClass="font-weight-bold"
                     aria-label="Hover to see more information"
                     runZone
                     name="info"
                     width="14" height="14"
                     class="aligner rise-blue pl-2"></streamline-icon>
  </div>
</div>

<template-attribute-components></template-attribute-components>
