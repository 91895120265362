<div class="attribute-editor-component">
  <div class="attribute-editor-row playlist-item-selector pt-3">
    <ul class="flex-row flex-wrap">
      @for (component of playlistComponents; track component) {
        <li class="align-center hide-empty u_position-relative" [ngClass]="{ 'disabled': component.requireFeature && !featuresService.isFeatureAvailable(component.requireFeature) }">
          <a
            tabindex="0"
            class="u_clickable pt-4 pb-3 u_lh-1"
            [ngClass]="{ 'extra-padding': component.icon === 'riseFolder' }"
            id="add-{{ component.type }}-button"
            (click)="addItem(component.type, component.options, component.requireFeature)"
            (keyup.enter)="addItem(component.type, component.options, component.requireFeature)"
            *requireRole="component.requireRole"
          >
            <streamline-icon [name]="component.icon"></streamline-icon><br>
            <div class="pt-3 pb-1">{{ component.title }}</div>
            @if (component.icon !== 'riseFolder') {
              <small>{{ component.subtitle || '&nbsp;' }}</small>
            }
          </a>
          @if (component.requireFeature && !featuresService.isFeatureAvailable(component.requireFeature)) {
            <upgrade-notice #UpgradeNotice [feature]="component.requireFeature" [overlay]="true"></upgrade-notice>
          }
        </li>
      }
    </ul>
  </div>
</div>
